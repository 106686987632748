import { apiAxiosV1, apiAxiosV2 } from '@/configs/axios';
import { Offer, OfferCount, OfferDetails, OfferV6 } from '@/model/offer';
import { AndroidService } from './Android';
import {
  API_END_POINT,
  API_END_POINT_V2_1,
  API_END_POINT_V2_2,
} from '@/configs/config';
import { OFFER_404 } from '@/constants/logs';
import { ggSentry } from '@/configs/sentry';
import { toast } from '@/utils';

async function fetchWithRetry(
  apiCall,
  retries = [5000, 10000, 20000],
  // retries = [100, 200, 500, 1000, 5000, 15000, 30000],
  condition = () => true
) {
  for (let i = 0; i < retries.length; i++) {
    try {
      return await apiCall(retries[i]); // Call the provided API function with timeout
    } catch (error) {
      if (!['ECONNABORTED', 'ETIMEDOUT'].includes(error.code)) {
        throw error; // Exit immediately if it's not a timeout error
      }

      if (i === retries.length - 1) {
        // ggSentry.catchExceptions(
        //   `Final timeout retry failed. Error: ${error.message}`
        // );
        throw error; // After last retry, throw the error
      }
    }
  }
}

export const OfferService = {
  async getActiveOffers({ page = 1, tags, sort }) {
    const params = {
      page,
      sort,
    };
    if (tags?.length) {
      params.tags = tags.join(',');
    }
    const url = API_END_POINT + `/v6/offer/user/list?list_type=active`;
    const res = await apiAxiosV2.get(url, {
      params,
      // timeout: 5000,
    });
    if (!res?.data?.results) {
      AndroidService.logEvent(OFFER_404, null);
    }
    return OfferV6.fromAll(res?.data?.results || []);
  },
  // async function getActiveOffers({ page = 1, tags, sort }) {
  //   const params = { page, sort };
  //   if (tags?.length) {
  //     params.tags = tags.join(',');
  //   }

  //   const url = API_END_POINT + `/v6/offer/user/list?list_type=active`;

  //   try {
  //     const results = await return apiAxiosV2.get(url, { params, timeout }).then(res => {
  //       if (!res?.data?.results) {
  //         AndroidService.logEvent(OFFER_404, null);
  //       }
  //       return OfferV6.fromAll(res?.data?.results || []);
  //     });

  //     return results;
  //   } catch (error) {
  //     if (page > 1) {
  //       console.warn(`Page ${page} failed:`, error.message);
  //       showToast(`Failed to load more offers. Please try again.`); // Show toast instead of blocking UI
  //       return []; // Return empty instead of throwing an error
  //     }

  //     throw error; // If page 1 fails, throw error to show error page
  //   }
  // },
  // async getActiveOffers({ page = 1, tags, sort }) {
  //   const params = {
  //     page,
  //     sort,
  //   };
  //   if (tags?.length) {
  //     params.tags = tags.join(',');
  //   }
  //   const url = API_END_POINT + `/v6/offer/user/list?list_type=active`;

  //   try {
  //     return fetchWithRetry(timeout => {
  //       return apiAxiosV2.get(url, { params, timeout }).then(res => {
  //         if (!res?.data?.results) {
  //           AndroidService.logEvent(OFFER_404, null);
  //         }
  //         return OfferV6.fromAll(res?.data?.results || []);
  //       });
  //     });
  //   } catch (error) {
  //     if (page > 1) {
  //       console.warn(`Page ${page} failed:`, error.message);
  //       toast(`Failed to load more offers. Please try again.`); // Show toast instead of blocking UI
  //       return []; // Return empty instead of throwing an error
  //     }

  //     throw error; // If page 1 fails, throw error to show error page
  //   }
  // },

  async getCompletedOffers({ page = 1 }) {
    const params = {
      page,
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=completed`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },
  async getExpiredOffers({ page = 1 }) {
    const params = {
      page,
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=expired`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  async getOngoingOffers({ page = 1 }) {
    const params = {
      page,
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=ongoing`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  // async getOfferDetails(offerId) {
  //   const url = API_END_POINT + `/v6/offer/user/details?offer_id=${offerId}`;
  //   const res = await apiAxiosV2.get(url);
  //   const data = res?.data;
  //   return OfferDetails.from(data);
  // },
  //call with retry
  async getOfferDetails(offerId) {
    const url = API_END_POINT + `/v6/offer/user/details?offer_id=${offerId}`;
    try {
      return fetchWithRetry(timeout => {
        return apiAxiosV2.get(url, { timeout }).then(res => {
          const data = res?.data;
          return OfferDetails.from(data);
        });
      });
    } catch (error) {
      console.warn(`Failed to load offer details:`, error.message);
      throw error;
    }
  },

  async getOfferCtaUrl({ rewardId, ga_id }) {
    const url = `/offer/reward/cta?reward_id=${rewardId}&ga_id=${ga_id}`;
    const res = await apiAxiosV1.get(url);
    return res?.data || null;
  },
  async redeemRetention(data) {
    const url = `/user_offer/retention/redeem`;
    const res = await apiAxiosV1.post(url, data);
    return;
  },

  // count apis
  async getOngoingOffersCount() {
    const url = 'user_offer/offerwall/ongoing/count';
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async getCompletedOffersCount() {
    const url = 'user_offer/offerwall/completed/count';
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async getExpiredOffersCount() {
    const url = 'user_offer/offerwall/expired/count';
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },

  async addVote({ offerId, vote, comment }) {
    const url = 'user_offer/vote/submit';
    const res = await apiAxiosV1.post(url, {
      offer_id: offerId,
      vote_state: vote,
      vote_comment: comment,
    });
    return res?.data;
  },
};
